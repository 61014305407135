import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"white shadow1 rounded",attrs:{"fluid":""}},[_c(VRow,[_c(VCol,{staticClass:"pb-0",attrs:{"cols":"12"}},[_c(VDataTable,{staticClass:"custom-data-table",attrs:{"headers":_vm.categoryHeaders,"items":_vm.$store.getters['category/categories'],"search":_vm.searchCategory,"loading":_vm.$store.getters['category/loadingCategories'],"loading-text":"Loading... Please wait"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c(VToolbar,{staticStyle:{"border-bottom":"1px solid #ddd !important","margin-bottom":"20px !important"},attrs:{"color":"white","elevation":0,"height":"50"}},[_c(VToolbarTitle,{staticClass:"subtitle-1"},[_vm._v("Category List")]),_c(VDivider,{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c(VForm,{staticClass:"custom-form"},[_c(VTextField,{staticStyle:{"width":"300px"},attrs:{"outlined":"","dense":"","hide-details":"","placeholder":"Search category","append-icon":"mdi-magnify"},model:{value:(_vm.searchCategory),callback:function ($$v) {_vm.searchCategory=$$v},expression:"searchCategory"}})],1),_c(VSpacer),_c('category-dialog',{ref:"categoryDialog",attrs:{"icon":true}})],1)]},proxy:true},(_vm.userType == 'super_admin' || _vm.userType == 'admin')?{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VIcon,_vm._g({attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.editCategory(item)}}},on),[_vm._v("mdi-circle-edit-outline")])]}}],null,true)},[_c('span',[_vm._v("Edit")])]),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VIcon,_vm._g({attrs:{"small":"","color":"error"},on:{"click":function($event){_vm.categoryId = item.id;_vm.$refs.confirmDialog.dialog = true}}},on),[_vm._v("mdi-delete-circle-outline")])]}}],null,true)},[_c('span',[_vm._v("Delete")])])]}}:null],null,true)}),_c('confirm-dialog',{ref:"confirmDialog",on:{"confirm":_vm.deleteCategory}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }